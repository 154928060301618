<template>
    <div class="extend">
        <div class="form-box">
            <el-form
                style="flex:1;margin-top:20px;margin-left: 40px;"
                label-position="left"
                label-width="100px"
            >
                <el-form-item label="公司名称">
                    <span class="text">{{ data.companyName }}</span>
                    <span
                        @click="copyText(data.companyName)"
                        style="margin-left:20px;color:#2370EB;cursor: pointer;"
                        >复制</span
                    >
                </el-form-item>
                <el-form-item label="审核结果">
                    <span class="text">
                        <el-radio v-model="auditStatus" label="1"
                            >延长通过</el-radio
                        >
                        <el-radio v-model="auditStatus" label="2"
                            >延长驳回</el-radio
                        >
                    </span>
                </el-form-item>
                <el-form-item
                    label="到期时间"
                    required
                    style="margin-left:-10px"
                    v-if="auditStatus == 1"
                >
                    <el-date-picker
                        v-model="tencentReportExpireTime"
                        size="small"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width:270px;margin-left:10px"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="auditStatus == 1">
                    <el-radio-group v-model="expireTime" size="small">
                        <el-radio-button
                            label="30天"
                            @click.native.prevent="clickTime('30天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="60天"
                            @click.native.prevent="clickTime('60天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="90天"
                            @click.native.prevent="clickTime('90天')"
                        ></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审核备注" v-if="auditStatus == 1">
                    <el-input
                        type="textarea"
                        :rows="2"
                        style="width:270px"
                        placeholder="请输入内容"
                        v-model="auditRemark"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="驳回原因"
                    required
                    style="margin-left:-10px"
                    v-if="auditStatus == 2"
                >
                    <el-input
                        type="textarea"
                        :rows="2"
                        style="width:270px;margin-left:10px"
                        placeholder="请输入内容"
                        v-model="rejectReason"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { tmAudit } from '@/api/user/business/customer';
export default {
    data() {
        return {
            data: {},
            auditRemark: '',
            rejectReason: '',
            auditStatus: '1',
            tencentReportExpireTime: '',
            expireTime: '30天',
        };
    },
    methods: {
        getData(row) {
            this.data = row;
            let date = new Date();
            this.tencentReportExpireTime =
                this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
        },
        // 到期时间点击
        clickTime(e) {
            // console.log(e);
            e === this.expireTime
                ? (this.expireTime = '')
                : (this.expireTime = e);
            let date = new Date();
            if (e == this.expireTime && e == '30天') {
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            } else if (e == this.expireTime && e == '60天') {
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -60) + ' 00:00:00';
            } else if (e == this.expireTime && e == '90天') {
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -90) + ' 00:00:00';
            } else {
                this.tencentReportExpireTime = '';
            }
        },
        onSubmit() {
            let data = {
                param: {
                    customerId: this.data.customerId,
                    customerTencentReportDO: {
                        id: this.data.id,
                        auditStatus: this.auditStatus,
                    },
                },
            };
            if (this.auditStatus == 1) {
                if (!this.tencentReportExpireTime) {
                    return this.$message.error('请选择到期时间');
                } else {
                    data.param.customerTencentReportDO.tencentReportExpireTime = this.tencentReportExpireTime;
                    data.param.customerTencentReportDO.auditRemark = this.auditRemark;
                }
            } else if (this.auditStatus == 2) {
                if (!this.rejectReason) {
                    return this.$message.error('请输入驳回原因');
                } else {
                    data.param.customerTencentReportDO.rejectReason = this.rejectReason;
                }
            }
            tmAudit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('审核成功');
                }
                this.onClose();
            });
        },
        onClose() {
            this.rejectReason = '';
            this.expireTime = '30天';
            this.auditStatus = '1';
            this.auditRemark = '';
            this.$emit('close');
        },
        //复制
        copyText(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.extend {
    width: 100%;

    .form-box {
        height: 360px;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
