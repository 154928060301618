<template>
    <div class="report">
        <el-form
            ref="form"
            style="flex:1;margin-top:20px;margin-left: 40px; padding-bottom: 50px;"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备品牌">
                <span class="text">腾讯企业邮箱</span>
            </el-form-item>
            <el-form-item label="公司名称">
                <span class="text">{{ form.companyName }}</span>
                <span
                    @click="copyText(form.companyName)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <el-form-item label="域名">
                <span class="text">{{ form.companyDomain }}</span>
                <span
                    v-if="form.companyDomain"
                    @click="copyText(form.companyDomain)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <div v-for="(item, index) in form.customerTencentReportDomainList">
                <el-form-item label="关联域名" :key="item.key">
                    <span class="text">{{
                        item.domain ? item.domain : '- -'
                    }}</span>
                    <span
                        @click="copyText(item.domain)"
                        style="margin-left:20px;color:#2370EB;cursor: pointer;"
                        >复制</span
                    >
                </el-form-item>
            </div>
            <el-form-item label="所在地">
                <span class="text">{{
                    $address.address(form.provinceId, form.cityId)
                }}</span>
            </el-form-item>
            <el-form-item label="联系人">
                <span class="text">{{ form.contactName }}</span>
                <span
                    v-if="form.contactName"
                    @click="copyText(form.contactName)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <el-form-item label="联系手机">
                <span class="text">{{ form.contactPhone }}</span>
                <span
                    v-if="form.contactPhone"
                    @click="copyText(form.contactPhone)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <el-form-item label="联系邮箱">
                <span class="text">{{ form.email }}</span>
                <span
                    v-if="form.email"
                    @click="copyText(form.email)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <el-form-item label="预计用户数">
                <span class="text">{{ form.number }}</span>
            </el-form-item>
            <el-form-item label="预计年限">
                <span class="text">{{ form.term }}</span>
            </el-form-item>
            <el-form-item label="申请备注">
                <span class="text">{{ form.remark }}</span>
            </el-form-item>
            <el-form-item label="审核结果">
                <span class="text">
                    <el-radio v-model="auditStatus" label="1"
                        >报备通过</el-radio
                    >
                    <el-radio v-model="auditStatus" label="2"
                        >报备驳回</el-radio
                    >
                </span>
            </el-form-item>
            <el-form-item
                label="到期时间"
                required
                style="margin-left:-10px"
                v-if="auditStatus == 1"
            >
                <el-date-picker
                    v-model="tencentReportExpireTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width:270px;margin-left:10px"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="auditStatus == 1">
                <el-radio-group v-model="expireTime" size="small">
                    <el-radio-button
                        label="30天"
                        @click.native.prevent="clickTime('30天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="60天"
                        @click.native.prevent="clickTime('60天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="90天"
                        @click.native.prevent="clickTime('90天')"
                    ></el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="审核备注" v-if="auditStatus == 1">
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:270px"
                    placeholder="请输入内容"
                    v-model="remark"
                >
                </el-input>
            </el-form-item>
            <el-form-item
                label="驳回原因"
                required
                style="margin-left:-10px"
                v-if="auditStatus == 2"
            >
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:270px;margin-left:10px"
                    placeholder="请输入内容"
                    v-model="rejectReason"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="报备库" v-if="auditStatus == 1">
                <el-select
                    v-model="libraryCategory"
                    size="small"
                    style="width:270px"
                    placeholder="请选择报备库"
                >
                    <el-option label="伽搜" value="1"></el-option>
                    <el-option label="布谷" value="2"></el-option>
                    <el-option label="品服" value="3"></el-option>
                    <el-option label="张作鹏" value="4"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left:270px"
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;"
                size="mini"
                @click="onSubmit"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import { tmReportUpdate } from '@/api/user/business/customer';
import { reportList } from '@/api/user/ae/tencent';
export default {
    data() {
        return {
            id: '',
            form: {},
            customerTencentReportDomainList: [
                {
                    domain: '',
                },
            ],
            auditStatus: '1',
            tencentReportExpireTime: '',
            rejectReason: '',
            remark: '',
            expireTime: '30天',
            libraryCategory: '1',
        };
    },
    methods: {
        echoData(data) {
            this.tencentReportExpireTime = '';
            this.rejectReason = '';
            this.remark = '';
            this.auditStatus = '1';
            this.id = data.customerId;
            let datas = {
                param: {
                    customerId: data.customerId,
                },
            };
            reportList(datas).then((res) => {
                this.form = res.data.list[res.data.list.length - 1];
                if (
                    res.data.list[res.data.list.length - 1]
                        .customerContactList &&
                    res.data.list[res.data.list.length - 1].customerContactList
                        .length > 0
                ) {
                    this.form.contactName =
                        res.data.list[
                            res.data.list.length - 1
                        ].customerContactList[0].contactName;
                    this.form.contactPhone =
                        res.data.list[
                            res.data.list.length - 1
                        ].customerContactList[0].contactPhone;
                    this.form.email =
                        res.data.list[
                            res.data.list.length - 1
                        ].customerContactList[0].email;
                }
                if (
                    res.data.list[res.data.list.length - 1]
                        .customerDomainList &&
                    res.data.list[res.data.list.length - 1].customerDomainList
                        .length > 0
                ) {
                    this.customerTencentReportDomainList =
                        res.data.list[
                            res.data.list.length - 1
                        ].customerDomainList;
                }
                let date = new Date();
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            });
        },

        onSubmit() {
            let data = {
                param: {
                    customerId: this.id,
                    customerTencentReportDO: {
                        auditStatus: this.auditStatus,
                    },
                },
            };
            if (this.auditStatus == 1) {
                if (!this.tencentReportExpireTime) {
                    return this.$message.error('请选择到期时间');
                } else {
                    data.param.customerTencentReportDO.tencentReportExpireTime = this.tencentReportExpireTime;
                    data.param.customerTencentReportDO.auditRemark = this.remark;
                    data.param.customerTencentReportDO.libraryCategory = this.libraryCategory;
                }
            } else if (this.auditStatus == 2) {
                if (!this.rejectReason) {
                    return this.$message.error('请输入驳回原因');
                } else {
                    data.param.customerTencentReportDO.rejectReason = this.rejectReason;
                }
            }

            tmReportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('审核成功');
                }
                this.onClose();
            });
        },
        // 到期时间点击
        clickTime(e) {
            // console.log(e);
            e === this.expireTime
                ? (this.expireTime = '')
                : (this.expireTime = e);
            let date = new Date();
            if (e == this.expireTime && e == '30天') {
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            } else if (e == this.expireTime && e == '60天') {
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -60) + ' 00:00:00';
            } else if (e == this.expireTime && e == '90天') {
                this.tencentReportExpireTime =
                    this.$searchTime.getNextDate(date, -90) + ' 00:00:00';
            } else {
                this.tencentReportExpireTime = '';
            }
        },
        onClose() {
            this.$emit('close');
        },
        //复制
        copyText(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.report {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .el-input {
        width: 270px;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }

    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;

        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
}
</style>
